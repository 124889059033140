.header-color{
  background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.landing-grid{
  background: #11998e;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  

}

.avatar-img{
  height: 250px;
  padding-top: 5em;
  border-radius: 45%;
}

.banner-text{
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
  
}

.banner-text h1{
  font-size: 66px;
  font-weight: bold;
  color: white;
}
.banner-text hr{
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p{
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i{
  color: white;
  font-size: 5em;
  padding: 5px;
}

.projects-grid{
  display: flex;

}

.contact-body {
  margin: auto;
  background-color: skyblue;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 800px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}

@media only screen and (max-width: 1024px){

.contact-body{
  background: none;
  
}

  .contact-cv{
  width: 100%;
}
.contact-info{
  width: 100%;
}
.contact-list{
  font-size: 10px;
}
.landing-grid{
  position: absolute;
}

}

@media only screen and (max-width: 600px){
  .avatar-img{
    height: 200px;
    padding-top: 3em;
  } 
  .banner-text h1{
    font-size: 30px;
  }
  .banner-text p{
    font-size: 10px;
  }

  .social-links i {
    font-size: 3em;
    padding: 4px;
  }
  .projects-grid{
    display: inline;
    padding: 10px;
    margin: 10px;
  }
  .contact-list i {
    font-size: 30px;
    padding-right: 2rem;
  }
  .contact-grid hr {
    border-top: 3px dotted black;

  }

}

@media only screen and (min-width: 1500px){
  .landing-grid{
    position: absolute;
  } 
}

